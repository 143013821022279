<template>
  <ToggleHeader v-bind="toggleHeaderProps" @toggle="toggle">
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
      mode="out-in"
    >
      <div v-if="visible">
        <hr class="mt-0 has-background-dark" />
        <div class="columns is-multiline">
          <div
            v-for="(value, key, idx) in group.data"
            :key="key"
            class="column is-12"
          >
            <div class="columns is-multiline is-mobile">
              <div class="column is-8 has-text-grey">{{ key }}</div>
              <div class="column is-4 has-text-right">
                {{ value || 'Data not provided' }}
              </div>
              <div class="column is-12 py-1" v-if="showSeparator(idx)">
                <hr class="hr is-marginless" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </ToggleHeader>
</template>

<script>
export default {
  name: 'AutoCheckAdditionalData',
  components: {
    ToggleHeader: () => import('core/components/ToggleHeader')
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data: () => ({ visible: true }),
  computed: {
    textColour() {
      return this.visible ? 'has-text-info' : 'has-text-grey-light'
    },
    toggleHeaderProps() {
      const { group, visible } = this
      const { title } = group
      return { title, visible, isLarge: true }
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible
    },
    showSeparator(idx) {
      return idx < Object.keys(this.group.data).length - 1
    }
  }
}
</script>
